<template>
  <div class="col-xl-12 col-lg-6 col-md-12 col-sm-6">
    <div class="card">
      <div class="card-header">
        <h5 class="p-0">
          <button
            class="btn btn-link ps-0"
            data-bs-toggle="collapse"
            data-bs-target="#collapseicon4"
            :aria-expanded="isActive ? 'true' : 'false'"
            aria-controls="collapseicon4"
            :class="[isActive ? 'active' : '']"
            v-on:click="toggle"
          >
            Fotos / Archivos
          </button>
        </h5>
      </div>
      <div :class="[isActive ? 'block' : ' show']" v-show="isActive">
        <div class="card-body photos filter-cards-view">
          <ul>
            <li v-for="p in photos" :key="p.id">
              <div class="latest-post">
                <img class="img-fluid" alt="" :src="p.image_url" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["posts"],
  data() {
    return {
      isActive: true,
    };
  },
  computed: {
    photos() {
      const userId = this.$route.params.id || localStorage.getItem("userId"); 
    return this.posts.filter(
      (item) => item.user_id === userId && item.image_url !== ""
    );
    },
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
  },
};
</script>
<style  scoped>
.photos {
  margin: 0; 
}

.photos ul {
  display: flex;
  flex-wrap: wrap; 
  list-style: none;
  padding: 0;
  margin: 0; 
}

.photos li {
  flex: 0 1 25%; 
  /* padding: 5px;  */
}

.photos img {
  width: 100%; 
  height: 100px; 
  object-fit: cover; 
  border-radius: 8px; 
}
</style>
