<template>
  <div class="col-12">
    <div class="">
      <div class="card">
        <div class="card-body">
          <div class="new-users-social">
            <div class="d-flex align-items-start">
              <img
                class="rounded-circle image-radius m-r-15"
                :src="
                  post.profile_photo !== null
                    ? post.profile_photo
                    : '@/assets/images/dashboard-2/product/shoes1.png'
                "
                alt=""
              />
              <div class="flex-grow-1">
                <router-link
                  class="icon-wrapper"
                  :to="{ name: 'myProfileId', params: { id: post.user_id } }"
                >
                  <h6 class="mb-0 f-w-700">{{ post.name }}</h6>
                </router-link>
                <p>{{ formattedDate(post.createdAt) }}</p>
              </div>
              <span class="pull-right mt-0"
                ><i data-feather="more-vertical"> </i
              ></span>
            </div>
          </div>
          <img
            v-if="loadingPostImage && post.image_url"
            class="img-social-full"
            alt="publicacion"
            :src="post.image_url"
   
              @click="openModal(post.image_url)"
          />

          <img
            v-else-if="post.image_url"
            src="@/assets/images/ajax-loader.gif"
            height="60" 
            class="img-loader"
          />
          <div class="timeline-content">
            <p
              v-if="showFullContent || post.content.length <= 100"
              class="mb-0 "
            >
            <span v-html="post.content"></span>
            </p>
            <p v-else class="mb-0 " v-html="truncatedContent" ></p>
            <button
              v-if="post.content.length > 100"
              @click="toggleShowFullContent"
              class="read-more-button"
            >
              {{ showFullContent ? "Ver menos" : "Ver más" }}
            </button>
            <div class="like-content mt-2">
              <span style="cursor: pointer" @click="updateLikePost">
                {{ post.likes }}    <i
      :class="['fa', 'fa-heart', isLiked ? 'font-danger' : '']"
      style="margin-left: 5px;"
    ></i>
                </span>
              <span class="pull-right d-none comment-number"
                ><span>20 </span
                ><span><i class="fa fa-share-alt me-0"></i></span></span
              ><span class="pull-right comment-number"
                ><span> {{ this.comments.length }} </span
                ><span><i class="fa fa-comments-o"></i></span
              ></span>
            </div>
            <div class="text-center">
              <a class="f-w-600" @click="toggle()" style="cursor: pointer"
                >Ver comentarios</a
              >
            </div>
            <div v-if="isActive" class="social-chat">
              <div v-for="item in comments" :key="item">
                <div class="d-flex align-items-start">
                  <img
                    class="img-50 img-fluid m-r-20 rounded-circle"
                    alt=""
                    :src="
                      item.profile_photo !== null
                        ? item.profile_photo
                        : '@/assets/images/dashboard-2/product/shoes1.png'
                    "
                  />
                  <div class="flex-grow-1">
                    <span class="f-w-600">
                      <span
                        >{{ formattedDate(item.createdAt) }}
                        <i class="fa fa-reply font-primary"></i></span
                    ></span>
                    <p class="mb-0" v-html="item.message_box"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="comments-box">
              <div class="d-flex align-items-center">
                <img
                  class="img-50 img-fluid m-r-20 rounded-circle"
                  alt=""
                  :src="
                    post.profile_photo !== null
                      ? post.profile_photo
                      : '@/assets/images/dashboard-2/product/shoes1.png'
                  "
                />
                <div class="flex-grow-1">
                  <div class="input-group text-box">
                    <input
                      class="form-control input-txt-bx"
                      type="text"
                      name="message-to-send"
                      placeholder="Escribe tu comentario"
                      v-model="comment.message_box"
                    />
                    <div
                      class="input-group-text"
                      @click="createNewComment"
                      style="cursor: pointer"
                    >
                      <i class="fa fa-send text-white"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

   <!-- Modal -->
   <div v-if="modalVisible" class="modal" @click="closeModal">
            <div class="modal-content" @click.stop>
              <!-- <span class="close" @click="closeModal">&times;</span> -->
              <img :src="modalImage" class="modal-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageMixin from "@/mixins/commen/imageMixin";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [imageMixin],
  props: ["post"],
  data() {
    return {
      isActive: false,
      showFullContent: false,
      loadingProfilePhoto: false,
      loadingPostImage: false,
      comments: [],
      comment: {
        user_id: "",
        post_id: "",
        message_box: "",
      },
      modalVisible: false,  // Estado del modal
      modalImage: '' ,       // URL de la imagen del modal

      isLiked: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user || {},
    }),
    truncatedContent() {
      return this.post.content.split(" ").slice(0, 100).join(" ") + "...";
    },
  },

  mounted() {

  setTimeout(() => {
    this.loadingPostImage = true;
  }, 5000);

},

  methods: {
    ...mapActions({
      createComment: "feed/createComment",
      getComments: "feed/getComments",
      updatePost: "feed/updatePost",
      getPosts: "feed/getPosts",
      getUserPatient: "user/getUserPatient",
    }),
    toggleShowFullContent() {
      this.showFullContent = !this.showFullContent;
    },
    async updateLikePost() {
      try {

        this.isLiked = !this.isLiked;
       this.post.likes += this.isLiked ? 1 : -1;


        let form = {
          id: this.post.id, 
          likes: this.post.likes,
        };
         await  this.updatePost({ payload: form });


        // await this.getPosts();
      } catch (error) {
        console.log(error);


      // Revertir cambios locales en caso de error
      this.isLiked = !this.isLiked;
      this.post.likes += this.isLiked ? 1 : -1;

      }
    },
    async createNewComment() {
      try {

         // Validar que el comentario no esté vacío
    if (!this.comment.message_box.trim()) {
      // Mostrar un mensaje de error
      this.$swal({
        icon: "error",
        title: "Error",
        text: "El comentario no puede estar vacío",
        timer: 2000,
        showConfirmButton: false,
      });
      return; // Salir de la función si el comentario está vacío
    }

        this.comment.post_id = this.post.id;
        this.comment.user_id = this.userInfo.id;
        await this.createComment({ payload: this.comment });
        this.getCommentByPost();
        this.comment.message_box = "";
      } catch (error) {
        console.log(error);
      }
    },

    async getCommentByPost() {
      try {
      const response = await this.getComments({ payload: this.post });

      this.comments = response.data;
    } catch (error) {
        console.error("Error al obtener los comentarios:", error);
      }
    },

    async getUseData(id) {
      const data = await this.getUserPatient({ userId: id });
    },
    toggle() {
      this.getCommentByPost();
      this.isActive = !this.isActive;
    },

    openModal(imageUrl) {
      this.modalImage = imageUrl;
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },



    formattedDate(date) {
      const timestamp = date; // El valor proporcionado
      const fecha = new Date(timestamp * 1000); // Multiplicamos por 1000 para convertirlo a milisegundos

      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const año = fecha.getFullYear();
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      return `${meses[fecha.getMonth()]}, ${dia}, ${año}`;
    },
  },
};
</script>

<style scoped>
.post-image-skeleton {
  width: 100%;
  height: 250px;
  background-color: #f3f3f3; /* Color del esqueleto */
  margin-bottom: 15px;
}

.loading {
  animation: loadingAnimation 1.5s infinite ease-in-out;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

/* .img-social-full {
  width: 100%;
  height: auto;
  cursor: pointer; 
} */


.read-more-button {
  background-color: #f0f2f5;
  border: none;
  color: #1877f2;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}
.img-social-full {
  width: 100%;
  object-fit: cover;
  height: 450px; /* Límite de altura */
  cursor: pointer;
}


.img-loader {
  display: block;
  margin: auto;
  position: relative; /* Asegúrate de que el contenedor tenga `position: relative` */
  top: 50%;
  transform: translateY(-50%);
}

.post {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 16px;
  margin: 16px;
  border-radius: 8px;
}

.post-header {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 12px;
}


.timeline-content p {
  white-space: pre-line; /* Esto permite interpretar saltos de línea */
  color: #000000 !important; 
}


.user-info h3 {
  margin: 0;
}

.post-actions button {
  color: #4a7cff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.comments {
  margin-top: 10px;
}

.comment {
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* margin-left: 10px; */
  border: 1px solid #dbd7d7;
  border-radius: 9px;
  padding: 15px;
  padding-bottom: 0;
}

.comment .user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment h4 {
  margin: 0;
  font-weight: bold;
}

.comment-content p {
  margin: 0;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro y semi-transparente */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
}


.modal-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: fit-content; /* Máximo ancho del modal */
  max-height: 80vh; /* Limita la altura de la imagen para que no sobrepase el viewport */
  background-color: #ffffff00 !important;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
}

.close {
  position: absolute; /* Posiciona el botón de cerrar en la esquina superior derecha */
  top: 0px;
  right: 20px;
  color: #024c9c;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

</style>
<style>
.timeline-content p {
    color: black;
}
</style>
