<template>
  <div class="col-xl-8 col-lg-7 card">
    <div class="card-body">
      <h5>Datos Generales</h5>
      <div class="row g-3">
        <div class="col-md-4">
          <label class="form-label">Nombres</label>
          <input
            type="text"
            class="form-control"
            id="name"
            placeholder="Nombres"
            v-model="form.first_name"
          />
        </div>
        <div class="col-md-4">
          <label class="form-label" for="validationCustom02">Apellidos</label>
          <input
            class="form-control"
            type="text"
            id="last_name"
            placeholder="Apellidos"
            v-model="form.last_name"
          />
        </div>
        <div class="col-md-4 mb-3">
          <label class="form-label" for="validationCustomUsername">Edad</label>
          <input
            class="form-control"
            id="validationCustomUsername"
            type="text"
            placeholder="Edad"
            v-model="form.age"
          />
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-4">
          <label class="form-label" for="validationCustom03">Sexo</label>
          <select
            class="form-select"
            id="gender"
            v-model="form.gender"
            readonly
          >
            <option
              v-for="(gender, index) in gendersValues"
              :value="gender.value"
              :key="gender.value + index"
            >
              {{ gender.label }}
            </option>
          </select>
        </div>
        <div class="col-md-8">
          <label class="form-label" for="validationCustom04">Universidad</label>
          <select
            v-model="form.university_id"
            id="inputGenre"
            class="form-select"
          >
            <option
              v-for="item in universidades"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="form-label" for="validationCustom05"
            >Formación Terapéutica</label
          >
          <select
            class="form-select"
            id="validationCustom04"
            v-model="form.terapeutic_fomation"
            required=""
          >
            <option
              v-for="(value, index) in specialitiesValues"
              :value="value"
              :key="value + index"
            >
              {{ value }}
            </option>
          </select>
        </div>
        <div class="col-md-8 ml-4">
          <label class="form-label">Instrucción: </label>
          <br />
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input ml-4"
              type="checkbox"
              id="bach"
              value="true"
              v-model="form.undergraduate"
            />
            <label class="form-check-label" for="bach"
              >Pre grado/bachiller</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="maestria"
              value="true"
              v-model="form.master"
            />
            <label class="form-check-label" for="maestria">Maestria</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="doctorado"
              value="true"
              v-model="form.doctorade"
            />
            <label class="form-check-label" for="doctorado">Doctorado</label>
          </div>
        </div>
      </div>
      <br />
      <div v-if="form.user_type !== 3">
        <label class="form-label">Adjuntar Sello y Firma (jpg/png)</label>
        <input
          class="form-control"
          type="file"
          @change="handleFileChange"
          accept="image/*"
        />
        <div v-if="form.url_file !== ''" class="mt-4">
          <img
            :src="form.url_file"
            alt="Descripción de la imagen"
            width="200"
            height="100"
          />
        </div>
      </div>
      <div class="mt-4">
        <label class="form-label">Subir nueva imagen de portada</label>
        <input
          class="form-control"
          type="file"
          @change="handleFileChange2"
          accept="image/*"
        />
        <div v-if="form.photo_large !== ''" class="mt-4">
          <img
            :src="form.photo_large"
            alt="Descripción de la imagen"
            width="200"
            height="100"
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <h5 class="mt-2">Perfil Laboral</h5>
      <div class="row g-3 mt-2">
        <div class="col-md-6">
          <br />
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="collegiate"
              value="option1"
              v-model="colegiate_state"
            />
            <label class="form-check-label" for="collegiate">Colegiado</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox2"
              value="true"
              v-model="form.enable_sys"
            />
            <label class="form-check-label" for="inlineCheckbox2"
              >Habilitado</label
            >
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label" for="colegiatura">Colegiatura</label>
          <input
            class="form-control"
            id="colegiatura"
            type="text"
            v-model="form.collegiate"
            placeholder="colegiatura"
            required=""
          />
        </div>
      </div>
      <div v-if="form.user_type !== 3" class="row g-3 mt-2">
        <div class="col-md-8">
          <label class="form-label" for="validationCustom05">Atiendo a:</label>
          <select
            class="form-select"
            id="validationCustom04"
            v-model="form.attend_id"
          >
            <option
              v-for="(value, index) in attens"
              :value="value.id"
              :key="value.id + index"
            >
              {{ value.title }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="form-label" for="validationCustom05"
            >Abordaje en:</label
          >
          <select
            class="form-select"
            id="validationCustom04"
            v-model="form.approach_id"
            required=""
          >
            <option
              v-for="value in approachs"
              :value="value.id"
              :key="value.id"
            >
              {{ value.name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="form.user_type !== 3" class="row g-3 mt-2">
        <div class="col-md-8">
          <label class="form-label">Modalidad: </label>
          <br />
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input ml-4"
              type="checkbox"
              id="presencial"
              value="option1"
              v-model="form.onsite"
            />
            <label class="form-check-label" for="presencial">Presencial</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="remote"
              value="true"
              v-model="form.remote"
            />
            <label class="form-check-label" for="remote">Remota</label>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label" for="price_session"
            >Precio de 1 sesion S/.</label
          >
          <input
            class="form-control"
            id="price_session"
            type="number"
            v-model="form.price_session"
            placeholder="S/ 10"
            required=""
          />
        </div>
      </div>

      <div class="row g-3 mt-2">
        <div class="col-md-3">
          <label class="form-label" for="nationality"
            >Nacionalidad</label
          >
          <input
            class="form-control"
            id="nationality"
            type="text"
            v-model="form.nationality"
            placeholder="Nacionalidad"
            required=""
          />
        </div>
      </div>
      <div class="row g-3 mt-2">
        <div class="col-md-3">
          <label class="form-label" for="country">País</label>
          <select
            id="country"
            v-model="form.country"
             class="form-select"
             @change="onCountryChange"
          >
            <option value="" disabled selected>Selecciona un país</option>
            <option
              v-for="(pais, idx) in paises"
              :key="idx"
              :value="pais.codigo"         
            >
              {{ pais.nombre }}
            </option>
          </select>
        </div>

        <div v-if="isPeru" class="col-md-3">
          <label class="form-label" for="departamento"
            >Departamento</label
          >
          <select
            v-model="form.state_country"
            @change="updateProvincias"
            class="form-control"
          >
            <option value="" disabled selected>Elige una opcion</option>
            <option
              v-for="(departamento, idx) in departamentos"
              :key="idx"
              :value="departamento.nombre_ubigeo"
            >
              {{ departamento.nombre_ubigeo }}
            </option>
          </select>
        </div>
        <div v-if="isPeru" class="col-md-3">
          <label class="form-label" for="provincia">Provincia</label>
          <select
            v-model="form.province"
            @change="updateDistritos"
            class="form-control"
          >
            <option value="" disabled selected>Elige una opcion</option>
            <option
              v-for="(provincia, idx) in provincias"
              :key="idx"
              :value="provincia.nombre_ubigeo"
            >
              {{ provincia.nombre_ubigeo }}
            </option>
          </select>
        </div>
        
        <div v-if="isPeru" class="col-md-3">
          <label class="form-label" for="distrito">Distrito</label>
          <select v-model="form.district" class="form-control">
            <option value="" disabled selected>Elige una opcion</option>
            <option
              v-for="(distrito, idx) in distritos"
              :key="idx"
              :value="distrito.nombre_ubigeo"
            >
              {{ distrito.nombre_ubigeo }}
            </option>
          </select>
        </div>

        <div class="row g-3 mt-2">
        <div class="col-md-12">
          <label class="form-label" for="address">Dirección</label>
          <input
            class="form-control"
            id="address"
            type="text"
            v-model="form.address"
            placeholder="dirección"
            required=""
            ref="addressInput"
          />
        </div>
      </div>
        <div id="map" v-if="form.country !== 'PE'" style="height: 400px; width: 100%;"></div>
      </div>
      <div class="row g-3 mt-2">
        <div class="col-md-4">
          <label class="form-label" for="phone">Whatsapp</label>
          <input
            class="form-control"
            id="phone"
            type="text"
            v-model="form.phone"
            placeholder="+51 929118922"
            required=""
          />
        </div>
      </div>
      <br />
      <div class="form-check form-check-inline">
        <input
          class="form-check-input ml-4"
          type="checkbox"
          id="buscador"
          value="true"
          v-model="form.searh_visible"
        />
        <label class="form-check-label" for="buscador"
          >Perfil visible en el buscador de psicólogos.</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input ml-4"
          type="checkbox"
          id="descuento"
          value="true"
          v-model="form.offer_30_firts_date"
        />
        <label class="form-check-label" for="descuento"
          >Dar el 30% de descuento en la primera consulta.</label
        >
      </div>
      <br />
      <div v-if="errors.length > 0" class="alert alert-danger">
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </div>
      <br />
      <button class="btn btn-primary mt-2" @click="editUser">
        Guardar cambios
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/mixins/commen/validationMixin";
import { mapState, mapActions } from "vuex";
import { uploadFile } from "../../../utils/s3Service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      user: {
        first_name: "",
        last_name: "",
        gender: "",
        email: "",
      },
      form: {
        id: "",
        age: 0,
        nationality: "",
        first_name: "",
        last_name: "",
        address: "",
        country: "", 
        state_country: "",
        province: "",
        district: "",
        phone: "",
        undergraduate: false,
        master: false,
        doctorade: false,
        collegiate: "",
        user_type: 0,
        enable_sys: false,
        university_id: "",
        onsite: false,
        remote: false,
        terapeutic_fomation: null,
        url_file: "",
        approach_id: "",
        price_session: "",
        attend_id: "",
        searh_visible: false,
        offer_30_firts_date: false,
      },
      specialitiesValues: [
        "Ninguno",
        "Cognitivo",
        "Contextual",
        "Humanista",
        "Psicoanalista",
        "Otros",
      ],
      gendersValues: [
        { label: "Masculino", value: "male" },
        { label: "Femenino", value: "female" },
      ],
      attens: [
        { id: 1, title: "Niños y Adolescentes" },
        { id: 2, title: "Jovenes" },
        { id: 3, title: "Adultos" },
      ],
      paises: [],
      departamentos: [],
      provincias: [],
      distritos: [],
      isPeru: false,
      map: null,
      marker: null,
      formSubmitted: false,
      colegiate_state: false,
      countryCoordinates: {
        us: { lat: 37.0902, lng: -95.7129, zoom: 4 }, // Estados Unidos
        ca: { lat: 56.1304, lng: -106.3468, zoom: 4 }, // Canadá
        mx: { lat: 23.6345, lng: -102.5528, zoom: 5 }, // México
        ar: { lat: -38.4161, lng: -63.6167, zoom: 5 }, // Argentina
        br: { lat: -14.2350, lng: -51.9253, zoom: 4 }, // Brasil
        cl: { lat: -35.6751, lng: -71.5430, zoom: 5 }, // Chile
        co: { lat: 4.5709, lng: -74.2973, zoom: 5 }, // Colombia
        pe: { lat: -9.189967, lng: -75.015152, zoom: 6 }, // Perú
        ve: { lat: 6.4238, lng: -66.5897, zoom: 5 }, // Venezuela
        ec: { lat: -1.8312, lng: -78.1834, zoom: 6 }, // Ecuador
        uy: { lat: -32.5228, lng: -55.7658, zoom: 6 }, // Uruguay
        bo: { lat: -16.2902, lng: -63.5887, zoom: 5 }, // Bolivia
        py: { lat: -23.4425, lng: -58.4438, zoom: 5 }, // Paraguay
        gt: { lat: 15.7835, lng: -90.2308, zoom: 6 }, // Guatemala
        bz: { lat: 17.1899, lng: -88.4976, zoom: 7 }, // Belice
        sv: { lat: 13.7942, lng: -88.8965, zoom: 7 }, // El Salvador
        hn: { lat: 15.2000, lng: -86.2419, zoom: 6 }, // Honduras
        ni: { lat: 12.8654, lng: -85.2072, zoom: 6 }, // Nicaragua
        cr: { lat: 9.7489, lng: -83.7534, zoom: 6 }, // Costa Rica
        do: { lat: 18.7357, lng: -70.1627, zoom: 6 }, // República Dominicana
        ht: { lat: 18.9712, lng: -72.2852, zoom: 7 }, // Haití
        jm: { lat: 18.1096, lng: -77.2975, zoom: 7 }, // Jamaica
        tt: { lat: 10.6918, lng: -61.2225, zoom: 8 }, // Trinidad y Tobago
        cu: { lat: 21.5218, lng: -77.7812, zoom: 6 }, // Cuba
        bb: { lat: 13.1939, lng: -59.5432, zoom: 9 }, // Barbados
        pa: { lat: 8.5380, lng: -80.7821, zoom: 6 }, // Panamá
        gy: { lat: 4.8604, lng: -58.9302, zoom: 6 }, // Guyana
        sr: { lat: 3.9193, lng: -56.0278, zoom: 7 }, // Surinam
        gf: { lat: 3.9339, lng: -53.1258, zoom: 7 }, // Guayana Francesa
        es: { lat: 40.4637, lng: -3.7492, zoom: 5 }, // España
        fr: { lat: 46.6034, lng: 1.8883, zoom: 5 }, // Francia
        de: { lat: 51.1657, lng: 10.4515, zoom: 5 }, // Alemania
        it: { lat: 41.8719, lng: 12.5674, zoom: 5 }, // Italia
        uk: { lat: 55.3781, lng: -3.4360, zoom: 5 }, // Reino Unido
        pl: { lat: 51.9194, lng: 19.1451, zoom: 6 }, // Polonia
        ro: { lat: 45.9432, lng: 24.9668, zoom: 7 }, // Rumania
        hu: { lat: 47.1624, lng: 19.5033, zoom: 6 }, // Hungría
        pt: { lat: 39.3998, lng: -8.2244, zoom: 6 }, // Portugal
        cz: { lat: 49.8175, lng: 15.4730, zoom: 7 }, // República Checa
        sk: { lat: 48.6690, lng: 19.6990, zoom: 7 }, // Eslovaquia
        si: { lat: 46.1512, lng: 14.9955, zoom: 7 }, // Eslovenia
        rs: { lat: 44.0165, lng: 21.0059, zoom: 6 }, // Serbia
        hr: { lat: 45.1000, lng: 15.2000, zoom: 7 }, // Croacia
        be: { lat: 50.8503, lng: 4.3517, zoom: 8 }, // Bélgica
        bg: { lat: 42.7338, lng: 25.4858, zoom: 6 }, // Bulgaria
        ad: { lat: 42.5078, lng: 1.5211, zoom: 10 }, // Andorra
        mt: { lat: 35.8997, lng: 14.5146, zoom: 10 }, // Malta
        ge: { lat: 41.9999, lng: 44.4000, zoom: 6 }, // Georgia
        az: { lat: 40.1431, lng: 47.5769, zoom: 6 }, // Azerbaiyán
        md: { lat: 47.4116, lng: 28.3699, zoom: 7 }, // Moldavia
        by: { lat: 53.7098, lng: 27.9533, zoom: 6 }, // Bielorrusia
        ua: { lat: 48.3794, lng: 31.1656, zoom: 6 }, // Ucrania
        mk: { lat: 41.6086, lng: 21.7452, zoom: 6 }, // Macedonia
        tr: { lat: 39.0558, lng: 34.7818, zoom: 6 }, // Turquía
        cy: { lat: 35.1264, lng: 33.4299, zoom: 8 }, // Chipre
        ru: { lat: 61.5240, lng: 105.3188, zoom: 3 }, // Rusia
        nl: { lat: 52.1326, lng: 5.2913, zoom: 6 }, // Países Bajos
        se: { lat: 60.1282, lng: 18.6435, zoom: 4 }, // Suecia
        no: { lat: 60.4720, lng: 8.4689, zoom: 5 }, // Noruega
        ch: { lat: 46.8182, lng: 8.2275, zoom: 6 }, // Suiza
        ie: { lat: 53.4129, lng: -8.2438, zoom: 6 }, // Irlanda
        al: { lat: 41.1533, lng: 20.1683, zoom: 7 }, // Albania
        at: { lat: 47.5162, lng: 14.5501, zoom: 7 }, // Austria
        ba: { lat: 43.9159, lng: 17.6791, zoom: 7 }, // Bosnia y Herzegovina
        dk: { lat: 56.2639, lng: 9.5018, zoom: 6 }, // Dinamarca
        ee: { lat: 58.5953, lng: 25.0136, zoom: 7 }, // Estonia
        is: { lat: 64.9631, lng: -19.0208, zoom: 6 }, // Islandia
        va: { lat: 41.9029, lng: 12.4534, zoom: 12 }, // Vaticano
        sm: { lat: 43.9336, lng: 12.4500, zoom: 10 }, // San Marino
        me: { lat: 42.7087, lng: 19.3744, zoom: 8 }, // Montenegro
        no: { lat: 60.4720, lng: 8.4689, zoom: 6 }, // Noruega
        fi: { lat: 61.9241, lng: 25.7482, zoom: 6 }, // Finlandia
        am: { lat: 40.0691, lng: 45.0382, zoom: 7 }, // Armenia
        lv: { lat: 56.8796, lng: 24.6032, zoom: 7 }, // Letonia
        lt: { lat: 55.1694, lng: 23.8813, zoom: 7 }, // Lituania
        lu: { lat: 49.8153, lng: 6.1296, zoom: 9 }, // Luxemburgo
        mc: { lat: 43.7384, lng: 7.4246, zoom: 10 }, // Mónaco
        ir: { lat: 32.4279, lng: 53.6880, zoom: 6 }, // Irán
        il: { lat: 31.0460, lng: 34.8516, zoom: 6 }, // Israel
        eg: { lat: 26.8205, lng: 30.8024, zoom: 6 }, // Egipto
        ae: { lat: 23.4240, lng: 53.8478, zoom: 6 }, // Emiratos Árabes Unidos
        sa: { lat: 25.3336, lng: 54.3612, zoom: 6 }, // Arabia Saudita
        kw: { lat: 29.3116, lng: 47.4817, zoom: 6 }, // Kuwait
        jo: { lat: 30.5851, lng: 36.2384, zoom: 6 }, // Jordania
        lb: { lat: 33.8547, lng: 35.8622, zoom: 6 }, // Líbano
        sy: { lat: 34.8020, lng: 38.9968, zoom: 6 }, // Siria
        qa: { lat: 25.3548, lng: 51.1838, zoom: 6 }, // Qatar
        kw: { lat: 29.3116, lng: 47.4817, zoom: 6 }, // Kuwait
        jo: { lat: 30.5851, lng: 36.2384, zoom: 6 }, // Jordania
        lb: { lat: 33.8547, lng: 35.8622, zoom: 6 }, // Líbano
        sy: { lat: 34.8020, lng: 38.9968, zoom: 6 }, // Siria
        qa: { lat: 25.3548, lng: 51.1838, zoom: 6 }, // Qatar
        ma: { lat: 31.7917, lng: -7.0926, zoom: 6 }, // Marruecos
        tn: { lat: 33.8869, lng: 9.5317, zoom: 6 }, // Túnez
        dz: { lat: 28.0338, lng: 1.6596, zoom: 6 }, // Argelia
        ml: { lat: 17.5706, lng: -3.9961, zoom: 6 }, // Mali
        mr: { lat: 20.3436, lng: -12.3503, zoom: 6 }, // Mauritania
        gn: { lat: 9.9455, lng: -9.6966, zoom: 6 }, // Guinea
        ci: { lat: 7.5399, lng: -5.5471, zoom: 6 }, // Costa de Marfil
        bf: { lat: 12.2383, lng: -1.5615, zoom: 6 }, // Burkina Faso
        ne: { lat: 17.6077, lng: 8.0816, zoom: 6 }, // Níger
        tg: { lat: 8.6195, lng: 0.8248, zoom: 6 }, // Togo
        bj: { lat: 6.35, lng: 2.35, zoom: 6 }, // Benín
        mu: { lat: -20.2833, lng: 57.55, zoom: 6 }, // Mauricio
        lr: { lat: 6.42, lng: -9.35, zoom: 6 }, // Liberia
        sl: { lat: 8.4605556, lng: -11.779889, zoom: 6 }, // Sierra Leona
        gh: { lat: 7.946527, lng: -1.023194, zoom: 6 }, // Ghana
        ng: { lat: 9.081999, lng: 8.675277, zoom: 6 }, // Nigeria
        td: { lat: 15.4541667, lng: 18.7322222, zoom: 6 }, // Chad
        cg: { lat: -0.228021, lng: 15.827659, zoom: 6 }, // Congo
        cd: { lat: 0.5333, lng: 25.3000, zoom: 6 }, // República Democrática del Congo
        pg: { lat: -6.314993, lng: 143.95555, zoom: 6 }, // Papúa Nueva Guinea
        ao: { lat: -12.3700, lng: 17.0533, zoom: 6 }, // Angola
        gw: { lat: 12.16957, lng: -15.35013, zoom: 6 }, // Guinea-Bissau
        gr: { lat: 39.0742, lng: 21.8243, zoom: 6 }, // Grecia
        cn: { lat: 35.8617, lng: 104.1954, zoom: 4 }, // China
        jp: { lat: 36.2048, lng: 138.2529, zoom: 5 }, // Japón
        in: { lat: 20.5937, lng: 78.9629, zoom: 5 }, // India
        kr: { lat: 35.9078, lng: 127.7669, zoom: 6 }, // Corea del Sur
        sa: { lat: 23.8859, lng: 45.0792, zoom: 5 }, // Arabia Saudita
        sg: { lat: 1.3521, lng: 103.8198, zoom: 10 }, // Singapur
        id: { lat: -0.7893, lng: 113.9213, zoom: 5 }, // Indonesia
        vn: { lat: 14.0583, lng: 108.2772, zoom: 6 }, // Vietnam
        th: { lat: 15.8700, lng: 100.9925, zoom: 6 }, // Tailandia
        my: { lat: 4.2105, lng: 101.9758, zoom: 6 }, // Malasia
        ph: { lat: 12.8797, lng: 121.7740, zoom: 6 }, // Filipinas
        au: { lat: -25.2744, lng: 133.7751, zoom: 4 }, // Australia
        nz: { lat: -40.9006, lng: 174.8860, zoom: 5 }, // Nueva Zelanda
        za: { lat: -29.6167, lng: 24.6535, zoom: 6 }, // Sudáfrica
        zw: { lat: -19.0154, lng: 29.1548, zoom: 6 }, // Zimbabue
        bd: { lat: 23.6849, lng: 90.3563, zoom: 6 }, // Bangladesh
        pk: { lat: 30.3753, lng: 69.3451, zoom: 6 }, // Pakistán
        lk: { lat: 7.8730, lng: 80.7718, zoom: 6 }, // Sri Lanka
        mm: { lat: 16.7424, lng: 96.8310, zoom: 6 }, // Myanmar
        tj: { lat: 38.8610, lng: 71.2760, zoom: 6 }, // Tayikistán
        af: { lat: 33.9391, lng: 67.7099, zoom: 6 }, // Afganistán
        bd: { lat: 23.6849, lng: 90.3563, zoom: 6 }, // Bangladesh
        pk: { lat: 30.3753, lng: 69.3451, zoom: 6 }, // Pakistán
        lk: { lat: 7.8730, lng: 80.7718, zoom: 6 }, // Sri Lanka
        mm: { lat: 16.7424, lng: 96.8310, zoom: 6 }, // Myanmar
        tj: { lat: 38.8610, lng: 71.2760, zoom: 6 }, // Tayikistán
        af: { lat: 33.9391, lng: 67.7099, zoom: 6 }, // Afganistán
    },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
      universidades: (state) => state.user.universidades,
      approachs: (state) => state.approach.approachs,
    }),
    
  },
  async mounted() {
    const data = {
      id: localStorage.getItem("userId"),
    };
    await this.loadPaises();
    await this.loadDepartamentos();
    await this.getUser({ payload: data });

    this.getApproachs();
    this.setData();

    // Cargar la API de Google Maps
    if (typeof google === 'undefined') {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap&loading=async`;
        script.async = true;
        script.defer = true;
        window.initMap = this.initMap;
        document.head.appendChild(script);
    } else {
        this.initMap();
    }
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      updateUser: "user/updateUser",
      getApproachs: "approach/getApproachs",
    }),
    validateForm() {
      this.errors = [];

      if (!this.form.first_name.trim()) {
        this.errors.push("El campo Nombres es requerido");
      }

      if (!this.form.last_name.trim()) {
        this.errors.push("El campo Apellidos es requerido");
      }

      if (!this.form.phone.trim()) {
        this.errors.push("El campo Whatsapp es requerido");
      }
      return this.errors.length === 0;
    },
    async setData() {
      for (const prop in this.userInfo) {
        if (this.userInfo.hasOwnProperty(prop)) {
          if (prop === "state_country") {
            this.form.state_country = this.userInfo.state_country;
            await this.updateProvincias();
          } else if (prop === "province") {
            this.form.province = this.userInfo.province;
            await this.updateDistritos();
          } else {
            this.form[prop] = this.userInfo[prop];
          }
        }
      }
      this.colegiate_state = this.form.collegiate !== "" ? true : false;
    },
      async loadPaises() {
      try {
          const response = await fetch("/data/ubigee/peru/paises.json");
          const data = await response.json();
          console.log(data); 
          this.paises = data; 
        } catch (error) {
          console.error("Error al cargar los países:", error);
        }
      },
    async loadDepartamentos() {
      try {
        const response = await fetch("/data/ubigee/peru/departamentos.json");
        const data = await response.json();
        this.departamentos = data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateProvincias() {
      try {
        const response = await fetch("/data/ubigee/peru/provincias.json");
        const data = await response.json();
        const departamento = this.departamentos.find(
          (item) => item.nombre_ubigeo === this.form.state_country
        );
        this.provincias = data[departamento.id_ubigeo] || [];
      } catch (error) {
        console.error(error);
      }
    },
    async updateDistritos() {
      try {
        const response = await fetch("/data/ubigee/peru/distritos.json");
        const data = await response.json();
        const province = this.provincias.find(
          (item) => item.nombre_ubigeo === this.form.province
        );
        this.distritos = data[province.id_ubigeo] || [];
      } catch (error) {
        console.error(error);
      }
    },
    getDistritoById(distritoId) {
      return this.distritos.find(
        (distrito) => distrito.nombre_ubigeo === distritoId
      );
    },
    fomattedData() {
      let payload = {};

      if (typeof this.form.country === "object") {
        this.form.country = this.form.country.name;
      }
      if (typeof this.form.state_country === "object") {
        this.form.state_country = this.form.state_country.nombre_ubigeo;
      }

      if (typeof this.form.province === "object") {
        this.form.province = this.form.province.nombre_ubigeo;
      }

      if (typeof this.form.district === "object") {
        this.form.district = this.form.district.nombre_ubigeo;
      }
      if (this.userInfo.user_type === 3) {
        payload = {
          id: this.form.id,
          age: this.form.age,
          nationality: this.form.nationality,
          gender: this.form.gender,
          address: this.form.address,
          country: this.form.country,
          state_country: this.form.state_country,
          province: this.form.province,
          district: this.form.district,
          phone: this.form.phone,
          undergraduate: this.form.undergraduate,
          master: this.form.master,
          doctorade: this.form.doctorade,
          collegiate: this.form.collegiate,
          user_type: this.form.user_type,
          searh_visible: this.form.searh_visible,
          offer_30_firts_date: this.form.offer_30_firts_date,
          university_id: this.form.university_id,
          photo_large: this.form.photo_large,
          enable: this.form.enable_sys,
        };
        return payload;
      } else {
        payload = {
          id: this.form.id,
          age: this.form.age,
          user_type: this.form.user_type,
          nationality: this.form.nationality,
          address: this.form.address,
          gender: this.form.gender,
          country: this.form.country,
          state_country: this.form.state_country,
          province: this.form.province,
          district: this.form.district,
          phone: this.form.phone,
          undergraduate: this.form.undergraduate,
          master: this.form.master,
          doctorade: this.form.doctorade,
          collegiate: this.form.collegiate,
          enable_sys: this.form.enable_sys,
          university_id: this.form.university_id,
          onsite: this.form.onsite,
          remote: this.form.remote,
          terapeutic_fomation: this.form.terapeutic_fomation,
          url_file: this.form.url_file,
          approach_id: this.form.approach_id,
          price_session: this.form.price_session,
          attend_id: this.form.attend_id,
          searh_visible: this.form.searh_visible,
          offer_30_firts_date: this.form.offer_30_firts_date,
          photo_large: this.form.photo_large,
          enable: this.form.enable_sys,
        };
        return payload;
      }
    },

    onCountryChange() {
      this.isPeru = this.form.country === 'PE';
      this.form.address="";
      if (this.map) {
      this.updateMapCenter(); // Actualiza el mapa al seleccionar un país
    }
      this.loadMap(); // Llama a la función para cargar el mapa
      this.initAutocomplete(); // Reinicia el autocompletado con las nuevas restricciones
    },

    loadMap() {
    if (this.form.country !== 'PE') {
      this.initMap(); // Inicializa el mapa si no es Perú
    } else {
      this.map = null; // Limpia el mapa si es Perú (o no haces nada si no se requiere)
    }
  },

    initAutocomplete() {
    // Obtén el código del país seleccionado
    const country = this.form.country;
    // Configura las restricciones para el autocompletado
    const options = {
      componentRestrictions: { country: country.toLowerCase() }, 
    };

    const addressInput = this.$refs.addressInput;
    if (!addressInput) {
    return;
  }

    const autocomplete = new google.maps.places.Autocomplete(addressInput, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (place.formatted_address) {
        this.form.address = place.formatted_address;
    }

        if (place.geometry && this.form.country !== 'PE') {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(15);
          this.marker.setPosition(place.geometry.location);
        }
      });
    },

    updateMapCenter() {
    const countryCode = this.form.country.toLowerCase();
    const countryData = this.countryCoordinates[countryCode];
    if (countryData && this.map) {
      this.map.setCenter({ lat: countryData.lat, lng: countryData.lng });
      this.map.setZoom(countryData.zoom);
    }
  },

    initMap() {
      if (!this.map && this.form.country !== 'PE') {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: -12.0464, lng: -77.0428 }, 
          zoom: 8,
        });
        this.marker = new google.maps.Marker({
          map: this.map,
          position: { lat: -12.0464, lng: -77.0428 },
        });
        //  this.initAutocomplete();
      }
      this.updateMapCenter();
    },

    async editUser() {
      try {
        const data = this.fomattedData();
        if (this.validateForm()) {
          const response = await this.updateUser({ payload: data });
          this.$router.push("/mi-perfil");
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.uploadFiles();
    },
    uploadFiles() {
      if (this.selectedFile) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile
        )
          .then((data) => {
            this.form.url_file = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleFileChange2(event) {
      this.selectedFile2 = event.target.files[0];
      this.uploadFiles2();
    },
    uploadFiles2() {
      if (this.selectedFile2) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile2
        )
          .then((data) => {
            this.form.photo_large = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
  },
};
</script>
