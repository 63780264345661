<!-- PatientReportPDF.vue -->
<template>
  <div id="pdfContent" style="display: none;">
    <div class="header">
      <img src="/img/logo.png" alt="Logo" class="logo" />
      <p>Sistema Integral de Gestión y Monitoreo de Atención Psicológica</p>
    </div>
    <h1 class= "text-align: center"><strong>Informe Psicológico</strong></h1>
    <section class="section">
  <div v-for="selectedType in selectedTypes" :key="selectedType.id">
    <div v-if="selectedType.id === 1" class="break mb-3">

      <h2>Datos Generales</h2>
      <div class="data-row">
        <span class="data-label">Nombre</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.first_name }} {{ patient?.last_name }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">DNI</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.DNI }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Sexo</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.gender === "male" ? "Masculino" : "Femenino" }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Fecha de Nacimiento</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ format(new Date(patient?.date_of_birth)) }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Edad</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.age }} años</span>
      </div>
      <div class="data-row">
        <span class="data-label">Estado Civil</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.civil_state }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Ubicación</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.address }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Email</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.email }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Estado</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.enable_sys ? "Activo" : "Archivado" }}</span>
      </div>

       </div>
  

 
    <div v-if="selectedType.id === 2" class="break mb-3">  
      <h2>Motivo de la Consulta</h2>
      <p>{{ patient?.reason }}</p>
  
    </div>

    <div v-if="selectedType.id === 3"  class="break mb-3">
   
      <h2>Historia Personal</h2>
      <div v-for="(item, index) in patient?.personal_hisotry" :key="index" class="data-row">
        <span class="data-label">{{ item.name }}</span>
        <span class="data-value">{{ item.content }}</span>
      </div>
    </div>


    <div v-if="selectedType.id === 4"  class="break mb-3">
      <h2>Plan de Tratamiento</h2>
      <div class="data-row">
        <span class="data-label">Detalle</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.treatment.detail }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Pre Diagnostico</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.treatment.pre_diag }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Número de Sesiones</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.treatment.number_sessions }}</span>
      </div>
      <div class="data-row">
        <span class="data-label">Modo</span>
        <span class="data-colon">:</span>
        <span class="data-value">{{ patient?.treatment.mode === 1 ? "Presencial" : "Remoto" }}</span>
      </div>
    </div>

    <div v-if="selectedType.id === 5"  class="break mb-3">
      <h2>Sesiones</h2>
      <table class="table">
        <thead>
          <tr>
            <th># Sesión</th>
            <th>Fecha</th>
            <th>Tratamiento</th>
            <th>Atendido</th>
            <th>Modalidad</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(session, index) in userSessions" :key="session.id">
            <td>{{ index + 1 }}</td>
            <td>{{ session.session_date }}</td>
            <td>{{ session.objective_target }}</td>
            <td>{{ session.companionship === 1 ? "Acompañado" : "Solo" }}</td>
            <td>{{ session.session_mode === 1 ? "Presencial" : "Remoto" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
  </div>

</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    selectedTypes: Array, 
  },

  computed: {
    ...mapState({
      patient: (state) => state.user.patient,
    }),
  },
  setup() {
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    return {
      format,
    };
  },
};
</script>

<style scoped>
.header {
  text-align: center;
  margin-bottom: 30px;
  background-color: #171829; 
  padding: 20px; 
  color: white; 
}

.logo {
  width: 100px;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  color: #333;
}

.section {
  margin-bottom: 20px;
  
}

h2 {
  font-size: 20px;
  color: #2c3e50;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 5px;

}

.data-row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.break {
  page-break-inside: avoid
}

.data-label {
  width: 180px; 
  font-weight: bold;
  color: #555;
  padding-right: 10px; 
}

.data-colon {
  width: 10px; 
  text-align: center; 
  color: #555; 
}

.data-value {
  flex-grow: 1; 
  color: #777;
  margin-left: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
  color: #333;
}
</style>
