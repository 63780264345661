<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-4">
          <h3>Imprimir</h3>
        </div>
        <div class="row">
          <label class="d-block">Seleccionar una opción</label>
          <multiselect
            v-model="selectedTypes"
            :options="types"
            :multiple="true"
            track-by="id"
            label="name"
          ></multiselect>
        </div>

        <div v-if="selectedTypes.length > 0" class="mt-3">
          <button @click="downloadPDF" class="small-primary-button mb-3">Descargar PDF</button>
        </div>
      </div>
      <br />
      <div class="col-12">
        <PatientReportPDF ref="pdfContent" 
        :selectedTypes="selectedTypes" />
        <div id="reportContent">
          <div v-for="selectedType in selectedTypes" :key="selectedType.id" class="row m-1">
            <div v-if="selectedType.id === 1" class="card_print col-12 mb-3">
              <div class="card-print-header">
                <h2>Datos Generales</h2>
              </div>
              <div class="card-body">
                <div class="data-row">
                  <span>Nombre:</span>
                  <span class="data-value">{{ patient?.first_name }} {{ patient?.last_name }}</span>
                </div>
                <div class="data-row">
                  <span>DNI:</span>
                  <span class="data-value">{{ patient?.DNI }}</span>
                </div>
                <div class="data-row">
                  <span>Sexo:</span>
                  <span class="data-value">{{ patient?.gender === "male" ? "Masculino" : "Femenino" }}</span>
                </div>
                <div class="data-row">
                  <span>Fecha de Nacimiento:</span>
                  <span class="data-value">{{ format(new Date(patient?.date_of_birth)) }}</span>
                </div>
                <div class="data-row">
                  <span>Edad:</span>
                  <span class="data-value">{{ patient?.age }} años</span>
                </div>
                <div class="data-row">
                  <span>Estado Civil:</span>
                  <span class="data-value">{{ patient?.civil_state }}</span>
                </div>
                <div class="data-row">
                  <span>Ubicación:</span>
                  <span class="data-value">{{ patient?.address }}</span>
                </div>
                <div class="data-row">
                  <span>Email:</span>
                  <span class="data-value">{{ patient?.email }}</span>
                </div>
                <div class="data-row">
                  <span>Estado:</span>
                  <span class="data-value">{{ patient?.enable_sys ? "Activo" : "Archivado" }}</span>
                </div>
              </div>
            </div>
            <div v-if="selectedType.id === 2" class="card_print col-12 mb-3">
              <div class="card-print-header">
                <h2>Motivo de la consulta</h2>
              </div>
              <div class="card-body">
                <div class="data-row">
                  {{ patient?.reason }}
                </div>
              </div>
            </div>
            <div v-if="selectedType.id === 3" class="card_print col-12 mb-3">
              <div class="card-print-header">
                <h2>Historia Personal</h2>
              </div>
              <div class="card-body">
                <div v-for="(item, index) in patient?.personal_hisotry" :key="index">
                  <div v-if="item.hasOwnProperty('name')" class="data-row">
                    <span>{{ item.name }}:</span>
                    <span>{{ item.content }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectedType.id === 4" class="card_print col-12 mb-3">
              <div class="card-print-header">
                <h2>Plan de tratamiento</h2>
              </div>
              <div class="card-body">
                <div class="data-row">
                  <span>Detalle:</span>
                  <span class="data-value">{{ patient?.treatment.detail }}</span>
                </div>
                <div class="data-row">
                  <span>Pre Diagnostico:</span>
                  <span class="data-value">{{ patient?.treatment.pre_diag }}</span>
                </div>
                <div class="data-row">
                  <span>Número de sesiones</span>
                  <span class="data-value">{{ patient?.treatment.number_sessions }}</span>
                </div>
                <div class="data-row">
                  <span>Modo:</span>
                  <span class="data-value">{{ patient?.treatment.mode === 1 ? "Presencial" : "Remoto" }}</span>
                </div>
              </div>
            </div>
            <div v-if="selectedType.id === 5" class="card_print col-12 mb-3">
              <div class="card-print-header">
                <h2>Sesiones</h2>
              </div>
              <div class="card-body-table">
                <div class="row pt-5">
                  <div class="col-12">
                    <table class="table table-hover mt-4">
                      <thead>
                        <tr>
                          <th scope="col"># Sesión</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Tratamiento</th>
                          <th scope="col">Atendido</th>
                          <th scope="col">Modalidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(sesion, index) in userSesions" :key="sesion.id">
                          <td>{{ index + 1 }}</td>
                          <td>{{ sesion.session_date }}</td>
                          <td>{{ sesion.objective_target }}</td>
                          <td>{{ sesion.companionship == 1 ? "Acompañado" : "Solo" }}</td>
                          <td>{{ sesion.session_mode == 1 ? "Presencial" : "Remoto" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import generalInfo from "@/components/myProfileSections/generalInfo.vue";
import { ref } from "vue";
import html2pdf from 'html2pdf.js';
import PatientReportPDF from "@/components/myProfileSections/PatientReportPDF.vue";

export default {
  components: {
    generalInfo,
    Multiselect,
    PatientReportPDF,
  },
  data() {
    return {
      pdfBlob: null,
      imgData: null,
      selectedTypes: [], 
      types: [
        { id: 1, name: "Datos Generales" },
        { id: 2, name: "Motivo de la consulta" },
        { id: 3, name: "Historia Personal" },
        { id: 4, name: "Plan de tratamiento" },
        { id: 5, name: "Sesiones" },
      ],
    };
  },
  setup() {
    const date = ref();

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    return {
      date,
      format,
    };
  },
  computed: {
    ...mapState({
      patient: (state) => state.user.patient,
      userSesions: (state) => state.sesion.userSesions,
    }),
  },
  async mounted() {
    if (this.$route.params.id !== undefined) {
      const data = {
        id: this.$route.params.id,
      };
      await this.getUserPatient({ payload: this.$route.params.id });
      await this.getSesionsById({ payload: data });
    }
  },
  methods: {
    ...mapActions({
      getUserPatient: "user/getUserPatient",
      psycos: "psyco/getPsyco",
      getSesionsById: "sesion/getSesionsById",
    }),
    async downloadPDF() { 
      const element = this.$refs.pdfContent.$el.innerHTML;
      const opt = {
        margin: 15,
        // filename: "reporte.pdf",
        // image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 }, 
        jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
      };
      html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("blob")
        .then((pdfBlob) => {
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, "_blank");
        const link  = document.createElement("a");
        link .href = url;
        link .download = "reporte.pdf"; 
        document.body.appendChild(link );
        link .click();
        document.body.removeChild(link );
        URL.revokeObjectURL(url);
        this.$toast.show("PDF generado y abierto en una nueva pestaña", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
        });
    } 
  },
};
</script>

<style scoped>
.card_print {
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 0px;
  page-break-inside: avoid;
  margin: 0 auto;
}

.card-print-header {
  background-color: #3498db;
  color: #fff;
  padding: 15px;
  text-align: center;
}

.card-body {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.card-body-table {
  padding: 10px;
}

.card h2 {
  margin-top: 0;
}

.card_print p {
  margin: 0;
  padding: 5px 0;
}

.data-row {
  display: flex;
}
.data-value {
  margin-left: 10px;
}
</style>
